<template>
  <template v-if="product.isOrderable">
    <button
      v-if="!hasProduct(product)"
      class="btn-buy"
      :class="[{ wide: isWide }, { minified: minifiedView }]"
      :aria-label="_T('@Buy')"
      @click="addToCart"
    >
      <img src="~~/assets/icons/cart.svg" alt="cart" />
      <span v-if="!minifiedView" class="btn-buy__title">{{ _T("@Buy") }}</span>
    </button>

    <button
      v-else
      class="btn-buy in-cart"
      :class="[{ wide: isWide }, { minified: minifiedView }]"
      :aria-label="_T('@In cart')"
      @click.prevent="modalStore.toggleModal(cartModal)"
    >
      <img src="~~/assets/icons/in-cart.svg" alt="cart" />
      <span v-if="!minifiedView" class="btn-buy__title">{{
        _T("@In cart")
      }}</span>
    </button>
  </template>

  <template v-else-if="product.status === 'preOrder'">
    <button
      class="btn-buy pre-order"
      :class="[{ wide: isWide }, { minified: minifiedView }]"
      :aria-label="_T('@Pre-order')"
      @click="modalStore.toggleModal(preOrderModal, product)"
    >
      <img src="~~/assets/icons/pre-order.svg" alt="cart" />
      <span v-if="!minifiedView" class="btn-buy__title">{{
        _T("@Pre-order")
      }}</span>
    </button>
  </template>

  <template v-else>
    <button
      class="btn-buy not-available"
      :class="[{ wide: isWide }, { minified: minifiedView }]"
      :aria-label="_T('@Inform')"
      @click="modalStore.toggleModal(preOrderModal, product)"
    >
      <img src="~~/assets/icons/mail.svg" alt="cart" />
      <span v-if="!minifiedView" class="btn-buy__title">{{
        _T("@Inform")
      }}</span>
    </button>
  </template>
</template>

<script setup>
import { useCartStore } from "~~/store/cartStore";
import {
  cartModal,
  preOrderModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import { Product } from "~/models/product.model";
import { toast } from "vue3-toastify";
import AddedToCart from "~/components/global/AddedToCart.vue";

const { product } = defineProps({
  product: { type: Product, required: true },
  isWide: { type: Boolean, required: false, default: false },
  minifiedView: { type: Boolean, required: false, default: false },
});

const cartStore = useCartStore();
const { hasProduct } = storeToRefs(cartStore);

const modalStore = useModalStore();

function addToCart() {
  cartStore.addItem(product);

  toast.success(_T("@Added to cart"), {
    autoClose: 1000,
    icon: AddedToCart,
    bodyClassName: "toast-cart-add",
    progressStyle: {
      "background-color": "#f36c21",
    },
  });
}
</script>

<style lang="scss" scoped>
.btn-buy {
  max-width: 277px;
  width: 100%;

  @include flex-container(row, center, center);
  gap: 8px;

  @include font(18, 24);
  color: white;

  border: 1px solid var(--color-primary-base);
  border-radius: 20px;
  background-color: var(--color-primary-base);

  padding: 8px 16px;

  transition: background-color 0.2s;

  @include mobile {
    max-width: 100%;
  }

  & img {
    @include mobile {
      width: 16px;
      height: 16px;
    }
  }

  &:hover {
    background-color: var(--color-primary-dark);
    border-color: var(--color-primary-dark);
  }

  &.minified {
    @include fixedHW(44px);

    border-radius: 8px;

    padding: 0;

    & img {
      width: 20px;
      height: 20px;
    }
  }

  &.wide {
    max-width: 100%;

    & img {
      @include mobile {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.pre-order {
    border: 1px solid #4490bb;
    background-color: #4490bb;

    &:hover {
      border-color: #295e7c;
      background-color: #295e7c;
    }

    &:active {
      border-color: #18374b;
      background-color: #18374b;
    }
  }

  &.in-cart {
    background-color: var(--color-primary-lightest);

    cursor: pointer;

    & span {
      color: var(--color-primary-base);
    }

    &:hover {
      background-color: var(--color-primary-lighter);
    }
  }

  &.not-available {
    background-color: #8a8a8a;
    border-color: #8a8a8a;
  }
}
</style>
